
import React from 'react'

import styles from './item.module.scss'


class Item extends React.Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		e.preventDefault();
		this.props.openModal(this.props.slug);
	}

	render() {
		return (
			<a href="#" className={styles.item} onClick={this.onClick}>
				<div className={styles.thumbnail}>
					{this.props.type === 'image' && (
						<div className={styles.image}><img src={this.props.image} alt="" /></div>
					)}

					{this.props.type === 'video' && (
						<div className={styles.video}><img src={this.props.image} alt="" /></div>
					)}

					{this.props.type === 'sound' && (
						<div className={styles.sound}><img src={this.props.jacket} alt="" /></div>
					)}
				</div>
				<h3 className={styles.title}>{this.props.title}</h3>
				<div className={styles.client}>{this.props.client}</div>
			</a>
		)
	}
}

export default Item