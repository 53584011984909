
import React from 'react'

import { connect } from 'react-redux'

import Nav from './nav'

import styles from './index.module.scss'


class Header extends React.Component {

	constructor(props) {
		super(props);

		this.scroll = this.scroll.bind(this);
		this.openNav = this.openNav.bind(this);
		this.closeNav = this.closeNav.bind(this);

		this.state = {
			isOpenNav : false
		}
	}

	scroll(e) {
		e.preventDefault();

		const target  = e.currentTarget.href.split('#')[1];
		const $target = window.$(`#${target}`);
		const scrollY = Math.min($target.offset().top, document.body.clientHeight - window.innerHeight)

		window.$('html,body').stop().animate({scrollTop:scrollY}, 800, 'ioX4');

		this.closeNav();
	}

	openNav() {
		this.setState({
			isOpenNav : true
		})
	}

	closeNav() {
		this.setState({
			isOpenNav : false
		})
	}

	isOpenNav() {
		console.log(this)
		return this.state.isOpenNav;
	}

	render() {
		return (
			<header className={styles.header}>
				<h1>
					<span><img src="/assets/img/common/logo.svg" alt="URBAN ROOF" /></span>
				</h1>

				<Nav {...this.props} scroll={this.scroll} isOpenNav={this.state.isOpenNav} />
				
				<div className={styles.openButton} aria-hidden={this.state.isOpenNav}>
					<button onClick={this.openNav}>
						<div><span></span></div>
					</button>
				</div>
				<div className={styles.closeButton} aria-hidden={!this.state.isOpenNav}>
					<button onClick={this.closeNav}>
						<div><span>Close</span></div>
					</button>
				</div>
			</header>
		)
	}
}

function mapStateToProps(state) {
	return {
		mode : state.breakpoint.mode
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)