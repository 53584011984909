
import {
	GET_WORKS,
	MORE_WORKS,
	FILTER_WORKS,
	SINGLE_WORKS,
	SHOW_WORKS_MODAL,
	HIDE_WORKS_MODAL
} from './types'


const post_per_page = 6;


export const getWorks = function() {
	return (dispatch, getState) => {

		const state = getState();

		window.$.ajax({
			url : 'https://www.urbanroof.net/app/api/v1/works/get',
			dataType : 'json',
			data : {
				post_per_page : post_per_page,
				current_page  : 1
			}
		}).done((json) => {
			dispatch({
				type     : GET_WORKS,
				current  : 1,
				selected : state.works.selected,
				tags     : json.tags,
				items    : json.items,
				hasMore  : json.current_page !== json.max_page
			})
		})
	}
}

export const moreWorks = function() {
	return (dispatch, getState) => {

		const state = getState();

		window.$.ajax({
			url : 'https://www.urbanroof.net/app/api/v1/works/get',
			dataType : 'json',
			data : {
				post_per_page : post_per_page * (state.works.current + 1),
				current_page  : 1,
				tags : state.works.selected
			}
		}).done((json) => {
			dispatch({
				type     : MORE_WORKS,
				current  : state.works.current + 1,
				selected : state.works.selected,
				tags     : json.tags,
				items    : json.items,
				hasMore  : json.current_page !== json.max_page
			})
		})
	}
}

export const filterWorks = function(tag, remove=false) {
	return (dispatch, getState) => {
		window.$.ajax({
			url : 'https://www.urbanroof.net/app/api/v1/works/get',
			dataType : 'json',
			data : {
				post_per_page : post_per_page,
				current_page  : 1,
				tags : remove ? 'all' : tag
			}
		}).done((json) => {
			dispatch({
				type     : FILTER_WORKS,
				current  : 1,
				selected : remove ? 'all' : tag,
				tags     : json.tags,
				items    : json.items,
				hasMore  : json.current_page !== json.max_page
			})
		})
	}
}

export const getSingle = function(slug) {
	return (dispatch, getState) => {

		const state = getState();

		window.$.ajax({
			url : 'https://www.urbanroof.net/app/api/v1/works/single',
			dataType : 'json',
			data : {
				slug : slug,
				tags : state.works.selected
			}
		}).done((json) => {
			dispatch({
				type : SINGLE_WORKS,
				data : json
			})
		})
	}
}


export const showModal = function() {
	return {type:SHOW_WORKS_MODAL}
}

export const hideModal = function() {
	return {type:HIDE_WORKS_MODAL}
}

export default {
	getWorks,
	moreWorks,
	filterWorks,
	getSingle,
	showModal,
	hideModal
}