
import React from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Wrapper  from './containers/wrapper'
import Header   from './components/common/header/index'
import Footer   from './components/common/footer/index'
import Home     from './components/home/index'



const App = () => (
	<Wrapper>
		<Header />

		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={Home} />
			</Switch>
		</BrowserRouter>

		<Footer />
	</Wrapper>
)

export default App
