
import React from 'react'

import { connect } from 'react-redux'

import FirstView from './firstview/index'
import Sidenav   from './sidenav/index'
import About     from './about/index'
import Image     from './image/index'
import Works     from './works/index'

import styles from './index.module.scss'


class Home extends React.Component {

	render() {
		return (
			<div className={styles.top}>
				<FirstView />
				<Sidenav />
				<About />
				<Image />
				<Works />
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

