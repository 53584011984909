
import React from 'react'

import styles from './index.module.scss'


const Image = (props) => (
	<div id="image" className={styles.image}></div>
)

export default Image
