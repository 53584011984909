
import React from 'react';


class Mailto extends React.Component {

	constructor(props) {
		super(props);
		this.mailto = this.mailto.bind(this);
	} 

	mailto(e) {
		if (window.confirm('メールを起動しますか?')) {
			return true;
		} else {
			e.preventDefault();
		}
	}

	render() {
		return (
			<a href="mailto:info@urbanroof.net" onClick={this.mailto}>{this.props.children}</a>
		)
	}
}

export default Mailto