
export const GET_WORKS = "GET_WORKS"

export const FILTER_WORKS = "FILTER_WORKS"

export const SINGLE_WORKS = "SINGLE_WORKS"

export const MORE_WORKS = "MORE_WORKS"

export const SHOW_WORKS_MODAL = "SHOW_WORKS_MODAL"

export const HIDE_WORKS_MODAL = "HIDE_WORKS_MODAL"