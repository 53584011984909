
import React from 'react'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Item from './item'

import styles from './index.module.scss'


const classNames = {
	enterDone   : styles.enterDone,
	enterActive : styles.enterActive,
	exitActive  : styles.exitActive
}

const timeout = {
	appear : 0,
	enter  : 300,
	exit   : 300
}

const List = (props) => (
	<div className={styles.list}>
		<ul>
		<TransitionGroup component={null} appear={true} enter={true} exit={true}>
			{props.items.map((item, i) => (
				<CSSTransition key={i} timeout={timeout} classNames={classNames}>
			 		<li><Item {...item} openModal={props.openModal} /></li>
				</CSSTransition>
			))}
		</TransitionGroup>
		</ul>
	</div>
)

export default List
