
import React from 'react'

import { connect } from 'react-redux'

import htmlActions from '../../states/html/actions'

import breakpointActions from '../../states/breakpoint/actions'

import styles from './wrapper.module.scss'


class Wrapper extends React.Component {

	constructor(props) {
		super(props);

		this.wrapper = React.createRef();
	}

	componentDidMount() {

		const computedStyle = window.getComputedStyle(this.wrapper.current, '::before');

		let type = null;

		window.scrollBarWidth = computedStyle.getPropertyValue('width');

		window.Polaris.util.onResize(() => {

			const _type = computedStyle.getPropertyValue('font-family');

			if (type !== _type) {
				type = _type;
				if (type === 'pc') this.props.breakpointPC();
				if (type === 'tb') this.props.breakpointTB();
				if (type === 'sp') this.props.breakpointSP();
			}
		})
	}

	render() {
		return (
			<div ref={this.wrapper} className={styles.wrapper}>{this.props.children}</div>
		)
	}
}


function mapStateToProps(state) {

	document.documentElement.className = state.html.classes.join(' ')

	return {}
}


function mapDispatchToProps(dispatch) {
	return {
		addHtmlClass    : (className) => dispatch(htmlActions.addClass(className)),
		removeHtmlClass : (className) => dispatch(htmlActions.removeClass(className)),
		toggleHtmlClass : (className) => dispatch(htmlActions.toggleClass(className)),

		breakpointPC : () => dispatch(breakpointActions.changedPC()),
		breakpointTB : () => dispatch(breakpointActions.changedTB()),
		breakpointSP : () => dispatch(breakpointActions.changedSP())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
