
import React, { useEffect, useState } from 'react'

import { CSSTransition } from 'react-transition-group'

import styles from './single.module.scss'

const animation = {
	exitActive: styles.exitActive,
}

const Single = ({ single, loadSingle }) => {

	const [visible, setVisible] = useState(window.sessionStorage.getItem('visible'))
	const [password, setPassword] = useState(``)

	const nextWork = (e) => {
		e.preventDefault();
		loadSingle(single.next.slug);
		window.$('#works-modal').get(0).scrollTop = 0;
	}

	const prevWork = (e) => {
		e.preventDefault();
		loadSingle(single.prev.slug);
		window.$('#works-modal').get(0).scrollTop = 0;
	}

	const submit = (e) => {
		e.preventDefault()

		if (password === "0329") {
			setVisible(true)
			window.sessionStorage.setItem('visible', true)
		}
	}


	if (single) {
		return (
			<div className={styles.wrapper}>

				{single.data.type === 'image' && (
					<div className={styles.image}>
						<img src={single.data.image} alt="" />
					</div>
				)}

				{single.data.type === 'video' && (
					<div className={styles.video}>
						<iframe title="youtube-player" src={`https://www.youtube.com/embed/${single.data.video_id}?rel=0&showinfo=0`} frameBorder="0" />

						{single.data.private && (
							<CSSTransition in={!visible} timeout={500} mountOnEnter={true} unmountOnExit={true} classNames={animation}>
								<div className={styles.password}>
									<form onSubmit={submit}>
										<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" />
										<input type="submit" value="" />
									</form>
								</div>
							</CSSTransition>
						)}
					</div>
				)}

				{single.data.type === 'sound' && (
					<div className={styles.sound}>
						<div className={styles.jacket}>
							<div className={styles.jacketimage}>
								<img src={single.data.jacket} alt="" />
							</div>
						</div>

						{single.data.sound_url && (
							<a href={single.data.sound_url} target="_blank" rel="noopener noreferrer">ご視聴はこちら</a>
						)}
					</div>

				)}

				<h3 className={styles.title}>{single.data.title}</h3>
				<h4 className={styles.client}>{single.data.client}</h4>

				<div className={styles.data}>
					<div className={styles.text}>
						<p>{single.data.description}</p>
					</div>

					{single.data.links && (
						<ul className={styles.links}>
							{single.data.links.map((link, i) => (
								<li key={i}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a></li>
							))}
						</ul>
					)}
				</div>

				<div className={styles.pager}>
					{single.prev && (
						<a href="#" onClick={prevWork}>
							<span className={styles.pagerLabel}>Prev</span>
							<span className={styles.pagerTitle}>{single.prev.title}</span>
							<span className={styles.pagerClient}>{single.prev.client}</span>
						</a>
					)}
					{single.next && (
						<a href="#" onClick={nextWork}>
							<span className={styles.pagerLabel}>Next</span>
							<span className={styles.pagerTitle}>{single.next.title}</span>
							<span className={styles.pagerClient}>{single.next.client}</span>
						</a>
					)}
				</div>
			</div>
		)
	} else {
		return null
	}
}

// class Single extends React.Component {

// 	constructor(props) {
// 		super(props);
// 		this.next = this.next.bind(this);
// 		this.prev = this.prev.bind(this);
// 	}

// 	next(e) {
// 	}

// 	prev(e) {
		
// 	}

// 	render() {
// 		if (single === null) return null;

// 		return (
			
// 		)
// 	}
// }

export default Single