
import React from 'react'

import Filter from './filter'

import styles from './index.module.scss'


const Tags = (props) => (
	<div className={styles.tags}>
		<ul>
			{props.tags.map((tag, i) => (
				<li key={i}>
					<Filter {...tag} filterWorks={props.filterWorks} selected={props.selected} />
				</li>
			))}
		</ul>
	</div>
)

export default Tags