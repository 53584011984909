
import React from 'react';

import { connect } from 'react-redux';

import Mailto from './mailto'

import styles from './index.module.scss';


const Footer = (props) => (
	<footer id="footer" className={styles.footer}>
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.left}>
					<p>If you’re interested in working or collaborating with us please <Mailto>contact us</Mailto></p>
				</div>
				<div className={styles.right}>
					<div>
						<dl>
							<dt>Contact us</dt>
							<dd><Mailto>info@urbanroof.net</Mailto></dd>
						</dl>
						<dl>
							<dt>Address</dt>
							<dd>〒182-0012<br />東京都調布市深大寺東町5-21-29</dd>
						</dl>
					</div>
					<div>
						<dl>
							<dt>Company name</dt>
							<dd>株式会社URBANROOF</dd>
						</dl>
						<dl>
							<dt>Establishment</dt>
							<dd>2019年3月29日</dd>
						</dl>
						<dl>
							<dt>Founder</dt>
							<dd>浅井千貴</dd>
						</dl>
					</div>
				</div>
				<div className={styles.bottom}>
					<ul>
						<li><a href="https://twitter.com/URBANROOF1" target="_blank" rel="noopener noreferrer">Twitter</a></li>
						<li><a href="https://www.instagram.com/urbanroof0329/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
					</ul>
					<span className={styles.copyright}>© 2019 urbanroof inc. All Rights Reserved.</span>
				</div>
			</div>
		</div>
	</footer>
)

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)