
import { createStore, combineReducers, applyMiddleware } from 'redux'

import thunk from 'redux-thunk'

import breakpointReducer from './breakpoint/reducers'

import htmlReducer from './html/reducers'

import worksReducer from './works/reducers'


const reducer = combineReducers({
	breakpoint : breakpointReducer,
	html  : htmlReducer,
	works : worksReducer
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;