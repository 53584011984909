
import React from 'react'

import { connect } from 'react-redux'

import styles from './index.module.scss'


class About extends React.Component {

	render() {
		return (
			<section id="about" className={styles.about}>
				<div className={styles.container}>
					<div className={styles.content}>
						<h2>Who We Are</h2>
						<div className={styles.box}>
							<em>Having a wide point of view</em>
							<p>URBANROOFは音楽を作る会社です。私たちは生活の中で数多くの音楽に触れますが、時にはすっと心に入ってきたり、突然揺さぶられたり、何気なく出会ったのに、人生の中で忘れられない瞬間になった経験が少なからずあるのではないでしょうか。私たちは、“都会の屋上“から俯瞰して多角的にその意図を捉え、最善の方法で、ただ通り過ぎるだけではなく、伝えるべき相手に「効く」音楽作りを大切にしています。</p>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
