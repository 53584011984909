
import React from 'react'

import styles from './index.module.scss'


class Sidenav extends React.Component {

	constructor(props) {
		super(props);

		this.ul = React.createRef();

		this.scroll = this.scroll.bind(this);

		this.state = {
			current : null
		}
	}

	componentDidMount() {

		const $ul = window.$(this.ul.current);

		const elements = window.$('#firstview, #about, #image, #works, #footer');

		this.params = [];

		window.Polaris.util.onResize(() => {
			this.params = elements.toArray().map((el, i) => {
				return {offset:elements.eq(i).offset().top, }
			});
		});

		window.Polaris.util.onScroll((t, b) => {

			let index = 0;

			for (let i=0, rate=0; i<elements.length; i++) {

				const _rate = window.Polaris.util.visibleAreaRate(elements.eq(i).offset().top, elements.eq(i).outerHeight(), t, b);

				if (_rate > rate) {
					rate = _rate;
					index = i;
				}
			}

			this.setState({
				current : elements.eq(index).attr('id')
			})

			const top = (t - window.innerHeight) / (document.body.clientHeight - 2 * window.innerHeight) * (window.innerHeight - $ul.outerHeight());

			$ul.css({
				transform : `translate3d(0, ${Math.max(top, 0)}px, 0)`
			})
		});
	}


	shouldComponentUpdate(nextProps, nextState) {
		return this.state.current !== nextState.current;
	}


	scroll(e) {
		e.preventDefault();

		const target  = e.currentTarget.href.split('#')[1];
		const $target = window.$(`#${target}`);
		const scrollY = Math.min($target.offset().top, document.body.clientHeight - window.innerHeight)

		window.$('html').stop().animate({scrollTop:scrollY}, 800, 'ioX4');
	}


	render() {
		return (
			<nav id="sidenav" className={styles.sidenav} data-current={this.state.current}>
				<ul ref={this.ul}>
					<li><a aria-selected={this.state.current === 'about'}  href="#about"  onClick={this.scroll} role="option"><span>About</span></a></li>
					<li><a aria-selected={this.state.current === 'works'}  href="#works"  onClick={this.scroll} role="option"><span>Works</span></a></li>
					<li><a aria-selected={this.state.current === 'footer'} href="#footer" onClick={this.scroll} role="option"><span>Contact</span></a></li>
				</ul>
			</nav>
		)
	}
}

export default Sidenav