
import React from 'react'

import { CSSTransition } from 'react-transition-group'

import Single from './single'

import styles from './index.module.scss'


const animation = {
	enterActive  : styles.enterActive,
	enterDone    : styles.enterDone,
	exitActive   : styles.exitActive,
}

class Modal extends React.Component {

	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
	}

	close(e) {
		e.preventDefault();
		this.props.closeModal();
	}

	render() {
		const style = {
			marginRight : window.scrollBarWidth
		}

		return (
			<CSSTransition in={this.props.showModal} timeout={500} mountOnEnter={true} unmountOnExit={true} classNames={animation}>
				<div id="works-modal" className={styles.modal}>
					<a href="#" className={styles.close} onClick={this.close} style={style}>Close</a>
					<Single {...this.props} />
				</div>
			</CSSTransition>
		)
	}
}

export default Modal