
import {
	ADD_HTML_CLASS,
	REMOVE_HTML_CLASS,
	TOGGLE_HTML_CLASS
} from './types'


export const addClass = function(className, delay=0) {
	if (delay > 0) {
		return (dispatch, getState) => {
			setTimeout(() => {
				dispatch({type:ADD_HTML_CLASS, className:className})
			}, delay)
		}
	} else {
		return {type:ADD_HTML_CLASS, className:className}
	}
	
}

export const removeClass = function(className, delay=0) {
	if (delay > 0) {
		return (dispatch, getState) => {
			setTimeout(() => {
				dispatch({type:REMOVE_HTML_CLASS, className:className})
			}, delay)
		}
	} else {
		return {type:REMOVE_HTML_CLASS, className:className}
	}
	
}

export const toggleClass = function(className, delay=0) {
	if (delay > 0) {
		return (dispatch, getState) => {
			setTimeout(() => {
				dispatch({type:TOGGLE_HTML_CLASS, className:className})
			}, delay)
		}
	} else {
		return {type:TOGGLE_HTML_CLASS, className:className}
	}
	
}


export default {
	addClass    : addClass,
	removeClass : removeClass,
	toggleClass : toggleClass
}