
import React from 'react'

import { connect } from 'react-redux'

import styles from './index.module.scss'


class FirstView extends React.Component {

	constructor(props) {
		super(props);

		this.wrap = React.createRef();
		this.video = React.createRef();
	}

	componentDidMount() {

		const $wrap = window.$(this.wrap.current);
		const $video = window.$(this.video.current);

		this.resizeID = window.Polaris.util.onResize(() => {
			const wrapW = $wrap.outerWidth();
			const wrapH = $wrap.outerHeight();
			const aspect = this.props.mode === 'pc' ? 9 / 16 : 720 / 404;

			let videoW = wrapW;
			let videoH = videoW * aspect;

			if (videoH < wrapH) {
				videoH = wrapH;
				videoW = videoH / aspect;
			}

			$video.css({
				top    : (wrapH - videoH) / 2,
				left   : (wrapW - videoW) / 2,
				width  : videoW,
				height : videoH
			})
		})
	}

	componentWillUnmount() {
		window.Polaris.util.offResize(this.resizeID);
	}

	render() {
		return (
			<div id="firstview" ref={this.wrap} className={styles.firstview}>
				<video ref={this.video} src={this.props.video} poster={this.props.poster} autoPlay muted loop playsInline></video>
			</div>
		)
	}
}

function mapStateToProps(state) {

	const mode = state.breakpoint.mode === 'sp' ? 'sp' : 'pc';

	return {
		mode : mode,
		video  : `/assets/mp4/video@${mode}.mp4`,
		poster : `/assets/img/home/poster@${mode}.jpg`,
	}
}

function mapDispatchToProps(dispatch) {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstView)
