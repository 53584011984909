
import {
	GET_WORKS,
	MORE_WORKS,
	FILTER_WORKS,
	SINGLE_WORKS,
	SHOW_WORKS_MODAL,
	HIDE_WORKS_MODAL
} from './types'

const initialState = {
	// 表示中のページ番号
	current : 1,

	// 選択タグ
	selected : 'all',

	// タグ一覧
	tags : [],

	// 表示works一覧
	items : [],

	hasMore : false,

	showModal : false,

	single : null
}


export default function worksReducer(state = initialState, action) {
	switch (action.type) {
		case GET_WORKS:
		case MORE_WORKS:
		case FILTER_WORKS:
			return {
				...state,
				current  : action.current,
				selected : action.selected,
				items    : action.items,
				tags     : action.tags,
				hasMore  : action.hasMore
			}

		case SINGLE_WORKS:
			return {...state, single:action.data}

		case SHOW_WORKS_MODAL:
			return {...state, showModal:true}

		case HIDE_WORKS_MODAL:
			return {...state, showModal:false}

		default:
			return state

	}
}