
import 'react-app-polyfill/ie11'

import React from 'react'

import ReactDOM from 'react-dom'

import { Provider } from "react-redux"

import store from './states/store.js'

import App from './views/app'

import './styles/common.scss'


ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>
, document.getElementById('root'))

