
import {
	BREAKPOINT_CHANGED_PC,
	BREAKPOINT_CHANGED_TB,
	BREAKPOINT_CHANGED_SP
} from './types'

const initialState = {
	mode : 'pc'
}


export default function breakpointReducer(state = initialState, action) {

	switch (action.type) {
		case BREAKPOINT_CHANGED_PC:
			return {...state, mode:'pc'}

		case BREAKPOINT_CHANGED_TB:
			return {...state, mode:'tb'}

		case BREAKPOINT_CHANGED_SP:
			return {...state, mode:'sp'}

		default:
			return state

	}
}