
import React from 'react'

import styles from './filter.module.scss'


class Filter extends React.Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		e.preventDefault();
		this.props.filterWorks(this.props.slug, this.props.selected === this.props.slug);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	render() {
		return (
			<button className={styles.filter} onClick={this.onClick} aria-selected={this.props.selected === this.props.slug} role="option">
				<span>{this.props.name}</span>
				<sup>{this.props.count}</sup>
			</button>
		)
	}
}

export default Filter