
import React from 'react';

import styles from './nav.module.scss';


const Nav = (props) => {
	if (props.mode === 'sp') {
		return (
			<div className={styles.spnav} aria-hidden={!props.isOpenNav}>
				<ul>
					<li><a href="#about"  onClick={props.scroll}><span>About</span></a></li>
					<li><a href="#works"  onClick={props.scroll}><span>Works</span></a></li>
					<li><a href="#footer" onClick={props.scroll}><span>Contact</span></a></li>
				</ul>
			</div>
		)
	} else {
		return (
			<div className={styles.pcnav}>
				<ul>
					<li><a href="#about"  onClick={props.scroll}><span>About</span></a></li>
					<li><a href="#works"  onClick={props.scroll}><span>Works</span></a></li>
					<li><a href="#footer" onClick={props.scroll}><span>Contact</span></a></li>
				</ul>
			</div>
		)
	}
}

export default Nav