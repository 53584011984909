
import React from 'react'

import { CSSTransition } from 'react-transition-group'

import styles from './more.module.scss'


const animation = {
	enterActive  : styles.enterActive,
	enterDone    : styles.enterDone,
	exitActive   : styles.exitActive,
}

const More = (props) => (
	<CSSTransition in={props.hasMore} timeout={300} mountOnEnter={true} unmountOnExit={true} classNames={animation}>
		<button className={styles.more} onClick={props.moreWorks}>
			<span>Show More Work</span>
		</button>
	</CSSTransition>
)

export default More