
import React from 'react'

import { connect } from 'react-redux'

import html from '../../../../states/html/actions'

import actions from '../../../../states/works/actions'

import Tags  from './tags/index'
import List  from './list/index'
import More  from './more'
import Modal from './modal/index'

import styles from './index.module.scss'


class Works extends React.Component {

	componentWillMount() {
		this.props.loadWorks()
	}

	render() {
		return (
			<section id="works" className={styles.works}>
				<div className={styles.container}>
					<div className={styles.content}>

						<h2>Creation of value</h2>

						<div className={styles.message}>
							<p>私たちは様々なシーンやジャンルに合わせた音楽制作を行っています。CM・映画・ドキュメンタリー等、各種映像のBGMから歌唱楽曲まで多岐にわたります。法人の方、個人様問わず、お気軽にご相談ください。</p>
						</div>

						<Tags {...this.props} />

						<List {...this.props} />

						<More {...this.props} />
					</div>
				</div>

				<Modal {...this.props} />

			</section>
		)
	}
}

function mapStateToProps(state) {
	return {
		tags      : state.works.tags,
		items     : state.works.items,
		selected  : state.works.selected,
		hasMore   : state.works.hasMore,
		showModal : state.works.showModal,
		single    : state.works.single
	}
}

function mapDispatchToProps(dispatch) {
	return {
		loadWorks : () => dispatch(actions.getWorks()),
		moreWorks : () => dispatch(actions.moreWorks()),

		filterWorks : (tag, remove) => {
			dispatch(actions.filterWorks(tag, remove))
		},

		loadSingle : (slug) => {
			dispatch(actions.getSingle(slug))
		},

		openModal : (slug) => {
			dispatch(html.addClass('show-modal'))
			dispatch(actions.getSingle(slug))
			dispatch(actions.showModal())

			window.$('#sidenav').css({marginRight:window.scrollBarWidth})
		},

		closeModal : () => {
			dispatch(actions.hideModal())

			setTimeout(() => {
				dispatch(html.removeClass('show-modal'))
				window.$('#sidenav').css({marginRight:''})
			}, 500)
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Works)
